import { PDFDocument } from 'pdf-lib';

export class PDFMerger {
  /**
   * Merges multiple PDFs provided as base64 strings.
   * @param pdfDataArray - Array of objects containing the base64 PDF data.
   * @returns A Promise that resolves to a base64 string of the merged PDF.
   */
  static async merge(pdfDataArray: { Data: string }[]): Promise<string> {
    // Create a new PDF document to hold the merged pages
    const mergedPdf = await PDFDocument.create();

    // Loop through each PDF provided
    for (const pdfData of pdfDataArray) {
      // Convert the base64 string to a Uint8Array
      const pdfBytes = PDFMerger.base64ToUint8Array(pdfData.Data);
      // Load the existing PDF
      const pdf = await PDFDocument.load(pdfBytes);
      // Copy all pages from the current PDF into the merged PDF
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    // Save the merged PDF to bytes
    const mergedPdfBytes = await mergedPdf.save();
    // Convert the merged PDF bytes to a base64 string and return
    return PDFMerger.uint8ArrayToBase64(mergedPdfBytes);
  }

  /**
   * Converts a base64 string to a Uint8Array.
   * @param base64 - The base64 encoded string.
   * @returns A Uint8Array representing the decoded bytes.
   */
  private static base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  /**
   * Converts a Uint8Array to a base64 string.
   * @param bytes - The Uint8Array to encode.
   * @returns A base64 encoded string.
   */
  private static uint8ArrayToBase64(bytes: Uint8Array): string {
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
