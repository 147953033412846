import { rdrRequests, authorizedRDRequests } from "../httpRequest";

const facilityApiRequest = {
    getAdditionalPlaceInfo: (placeId: number) => rdrRequests.get(`fd/placeinfo/additional-place-info/${placeId}`),
    getPlaceAlerts: (placeId: number) => rdrRequests.get(`search/nd3alerts/${placeId}`),
    getND2PlaceAlerts: (placeId: number) => rdrRequests.get(`search/alerts/${placeId}`),
    getNextPrevAvailability: (requestData: any) => rdrRequests.get(`search/${requestData.availabilityType}/facility/${requestData.facilityId}/unitType/${requestData.unitTypeId}/startdate/${requestData.startDate}/nights/${requestData.nights}/weekend/${requestData.weekend}/isADA/${requestData.isADA}`),
    saveWaiteList: (values: any) => authorizedRDRequests.post(`webaccessfacility/save/waitlistinfo`, values)
}

export default facilityApiRequest;