import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hidePreLoader, showPreLoader } from "../loader/loaderSlice";
import applicationApiRequest from "../../app/api/application/applicationApiRequest";
import { ApplicationCustomerDetail, ApplicationFeeRequest, ApplicationPassInformationRequest, ApplicationPermitOperationRequest, ApplicationQuestionAnswerRequest, ApplicationStatusRequest, CustomerApplicationDataRequest, CustomerApplicationDocumentsRequest, CustomerApplicationFinalizeDetailsRequest, CustomerPassApplicationRequest, DeleteCustomerApplicationDocumentsRequest, DocumentListByApplicationRequest, ReSubmitApplicationDocumentRequest, SaveApplicationQuestionAnswerRequest } from "../../app/models/application/application";
import { onItemAddInCart } from "./shoppingCartSlice";
import navigationService from "../../app/history/history";

interface CustomerState {
    applicationPassList: any,
    customerApplication: any,
    remainingPassesForApplication: any,
    detailForApplicationPermitDate: any,
    permitStartEndDate: any,
    applicationPassDetail: any,
    customerPassApplicationExists: any,
    customerApplicationData: any,
    applicationGlobalInputField: any,
    applicationQuestionAnswer: any,
    applicationQuestion: any,
    documentListByApplication: any,
    deleteResponse: any,
    manageDocumentsResponse: any,
    saveApplicationDocuments: any,
    applicationFee: any,
    appFinalizeStatus: any,
    applicationToShoppingCartResponse: any,
    applicationStatusDetails: any,
    resubmitApplicationDocumentResponse: any,
    applicationQuestionAnswerResponse: any
}

const initialState: CustomerState = {
    applicationPassList: [],
    customerApplication: null,
    remainingPassesForApplication: null,
    detailForApplicationPermitDate: null,
    permitStartEndDate: null,
    applicationPassDetail: null,
    customerPassApplicationExists: null,
    customerApplicationData: null,
    applicationGlobalInputField: null,
    applicationQuestionAnswer: null,
    applicationQuestion: null,
    documentListByApplication: null,
    deleteResponse: null,
    manageDocumentsResponse: null,
    saveApplicationDocuments: null,
    applicationFee: null,
    appFinalizeStatus: null,
    applicationToShoppingCartResponse: null,
    applicationStatusDetails: null,
    resubmitApplicationDocumentResponse: null,
    applicationQuestionAnswerResponse: null
}

export const getApplicationPasses = createAsyncThunk<any, { customerId : number }>(
    'application/getApplicationPasses',
    async (_, thunkAPI) => {
        try {
            // thunkAPI.dispatch(showPreLoader());
            const applicationPassList = await applicationApiRequest.getApplicationPasses(_.customerId);
            // thunkAPI.dispatch(hidePreLoader());
            return applicationPassList;
        } catch (error: any) {
            // thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerApplication = createAsyncThunk<any, { request: ApplicationPassInformationRequest }>(
    'application/getCustomerApplication',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerApplication = await applicationApiRequest.getCustomerApplication(request);
            thunkAPI.dispatch(hidePreLoader());
            return customerApplication;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDetailForApplicationPermitDate = createAsyncThunk<any, { customerApplicationId: number }>(
    'application/getDetailForApplicationPermitDate',
    async ({ customerApplicationId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const detailForApplicationPermitDate = await applicationApiRequest.getDetailForApplicationPermitDate(customerApplicationId);
            thunkAPI.dispatch(hidePreLoader());
            return detailForApplicationPermitDate;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPermitStartEndDate = createAsyncThunk<any, { passId: number }>(
    'application/getPermitStartEndDate',
    async ({ passId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const permitStartEndDate = await applicationApiRequest.getPermitStartEndDate(passId);
            thunkAPI.dispatch(hidePreLoader());
            return permitStartEndDate;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const setApplicationPassDetail = createAsyncThunk<any, { applicationPassDetails: any }>(
    'application/setApplicationPassDetail',
    async ({ applicationPassDetails }, thunkAPI) => {
        try {
            return applicationPassDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const checkCustomerPassApplicationExistsOrNot = createAsyncThunk<any, { request: CustomerPassApplicationRequest }>(
    'application/checkCustomerPassApplicationExistsOrNot',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerPassApplicationExists = await applicationApiRequest.checkCustomerPassApplicationExists(request);
            thunkAPI.dispatch(hidePreLoader());
            return customerPassApplicationExists;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveCustomerApplicationInfo = createAsyncThunk<any, { request: CustomerApplicationDataRequest }>(
    'application/saveCustomerApplicationInfo',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const customerApplicationData = await applicationApiRequest.saveCustomerApplicationData(request);
            thunkAPI.dispatch(hidePreLoader());
            return customerApplicationData;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getApplicationGlobalInputField = createAsyncThunk<any, { applicationId: number }>(
    'application/getApplicationGlobalInputField',
    async ({ applicationId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationGlobalInputField = await applicationApiRequest.getApplicationGlobalInputField(applicationId);
            thunkAPI.dispatch(hidePreLoader());
            return applicationGlobalInputField;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getApplicationQuestionAnswer = createAsyncThunk<any, { request: ApplicationQuestionAnswerRequest }>(
    'application/getApplicationQuestionAnswer',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationQuestionAnswer = await applicationApiRequest.getApplicationQuestionAnswer(request);
            thunkAPI.dispatch(hidePreLoader());
            return applicationQuestionAnswer;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getApplicationQuestion = createAsyncThunk<any, { applicationId: number }>(
    'application/getApplicationQuestion',
    async ({ applicationId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationQuestion = await applicationApiRequest.getApplicationQuestion(applicationId);
            thunkAPI.dispatch(hidePreLoader());
            return applicationQuestion;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveApplicationCustomerInfo = async (request: ApplicationCustomerDetail, callback: any) => {
    try {
        const applicationCustomerInfo = await applicationApiRequest.saveApplicationCustomerInfo(request);
        callback(applicationCustomerInfo);
    } catch (error: any) {
        callback(null);
    }
}

export const saveApplicationQuestionAnswer = createAsyncThunk<any, { request: SaveApplicationQuestionAnswerRequest }>(
    'application/saveApplicationQuestionAnswer',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationQuestionAnswerResponse = await applicationApiRequest.saveApplicationQuestionAnswer(request);
            thunkAPI.dispatch(hidePreLoader());
            return applicationQuestionAnswerResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDocumentListByApplication = createAsyncThunk<any, { request: DocumentListByApplicationRequest }>(
    'application/getDocumentListByApplication',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const documentListByApplication = await applicationApiRequest.getDocumentListByApplication(request);
            thunkAPI.dispatch(hidePreLoader());
            return documentListByApplication;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteCustomerApplicationDocuments = createAsyncThunk<any, { request: DeleteCustomerApplicationDocumentsRequest }>(
    'application/deleteCustomerApplicationDocuments',
    async ({ request }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(showPreLoader());
            const deleteResponse = await applicationApiRequest.deleteCustomerApplicationDocuments(request);
            // thunkAPI.dispatch(hidePreLoader());
            return deleteResponse;
        } catch (error: any) {
            // thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const manageDocumentsToEnigmaVault = createAsyncThunk<any, { request: any }>(
    'application/manageDocumentsToEnigmaVault',
    async ({ request }, thunkAPI) => {
        try {
            // thunkAPI.dispatch(showPreLoader());
            const manageDocumentsResponse = await applicationApiRequest.manageDocumentsToEnigmaVault(request);
            // thunkAPI.dispatch(hidePreLoader());
            return manageDocumentsResponse;
        } catch (error: any) {
            // thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveApplicationDocuments = createAsyncThunk<any, { request: CustomerApplicationDocumentsRequest }>(
    'application/saveApplicationDocuments',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const saveApplicationDocuments = await applicationApiRequest.saveApplicationDocuments(request);
            thunkAPI.dispatch(hidePreLoader());
            return saveApplicationDocuments;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getApplicationFee = createAsyncThunk<any, { request: ApplicationFeeRequest }>(
    'application/getApplicationFee',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationFee = await applicationApiRequest.getApplicationFee(request);
            thunkAPI.dispatch(hidePreLoader());
            return applicationFee;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const finalizeApplication = createAsyncThunk<any, { request: CustomerPassApplicationRequest }>(
    'application/finalizeApplication',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const appFinalizeStatus = await applicationApiRequest.finalizeApplication(request);
            thunkAPI.dispatch(hidePreLoader());
            return appFinalizeStatus;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const applicationAddToCart = createAsyncThunk<any, { request: ApplicationPermitOperationRequest }>(
    'application/applicationAddToCart',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationToShoppingCartResponse = await applicationApiRequest.applicationAddToCart(request);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, applicationToShoppingCartResponse, null);
            if (isSuccess) {
                navigationService.navigation("/Customers/ShoppingCart");              
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }
            return applicationToShoppingCartResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getApplicationStatusDetails = createAsyncThunk<any, { request: ApplicationStatusRequest }>(
    'application/getApplicationStatusDetails',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const applicationStatusDetails = await applicationApiRequest.getApplicationStatusDetails(request);
            thunkAPI.dispatch(hidePreLoader());
            return applicationStatusDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const printPassApplicationFeeReceipt = createAsyncThunk<any, {requestData: any }>(
    'Customer/RePrintByConfirmationNo',
    async ({ requestData}, thunkAPI) => {
        try {
            // thunkAPI.dispatch(showPreLoader());
            let res = null;
            res = await applicationApiRequest.printPassApplicationFeeReceipt(requestData);
            thunkAPI.dispatch(hidePreLoader());
            if (res?.$values && res?.$values?.length > 0 && res?.$values[0]?.Data) {
                if (res.$values[0].Data) {
                    var binary_string = (window as any).atob(res.$values[0].Data);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                        bytes[i] = binary_string.charCodeAt(i);
                    }
                    var blob = new Blob([bytes.buffer], { type: 'application/pdf' });
                    var url = window.URL.createObjectURL(blob);
                    thunkAPI.dispatch(hidePreLoader());
                    const width = window.innerWidth;
                    const height = window.innerHeight;
                    const features = `width=${width},height=${height}`;
                    window.open(url,"_blank",features);
                } else {
                    // thunkAPI.dispatch(hidePreLoader());
                }
            }
            else {
                // thunkAPI.dispatch(hidePreLoader());
            }
        } catch (error: any) {
            // thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveResubmitApplicationDocument = createAsyncThunk<any, { request: ReSubmitApplicationDocumentRequest }>(
    'application/saveResubmitApplicationDocument',
    async ({ request }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const resubmitApplicationDocumentResponse = await applicationApiRequest.saveResubmitApplicationDocument(request);
            thunkAPI.dispatch(hidePreLoader());
            return resubmitApplicationDocumentResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
    },
    extraReducers: (builder => {
        builder.addCase(getApplicationPasses.rejected, (state, action) => {
            state.applicationPassList = [];
        });
        builder.addCase(getCustomerApplication.rejected, (state, action) => {
            state.customerApplication = null;
        });
        builder.addCase(getDetailForApplicationPermitDate.rejected, (state, action) => {
            state.detailForApplicationPermitDate = null;
        });
        builder.addCase(getPermitStartEndDate.rejected, (state, action) => {
            state.permitStartEndDate = null;
        });
        builder.addCase(setApplicationPassDetail.rejected, (state, action) => {
            state.applicationPassDetail = null;
        });
        builder.addCase(checkCustomerPassApplicationExistsOrNot.rejected, (state, action) => {
            state.customerPassApplicationExists = null;
        });
        builder.addCase(saveCustomerApplicationInfo.rejected, (state, action) => {
            state.customerApplicationData = null;
        });
        builder.addCase(getApplicationGlobalInputField.rejected, (state, action) => {
            state.applicationGlobalInputField = null;
        });
        builder.addCase(getApplicationQuestionAnswer.rejected, (state, action) => {
            state.applicationQuestionAnswer = null;
        });
        builder.addCase(getApplicationQuestion.rejected, (state, action) => {
            state.applicationQuestion = null;
        });
        builder.addCase(getDocumentListByApplication.rejected, (state, action) => {
            state.documentListByApplication = null;
        });
        builder.addCase(deleteCustomerApplicationDocuments.rejected, (state, action) => {
            state.deleteResponse = null;
        });
        builder.addCase(manageDocumentsToEnigmaVault.rejected, (state, action) => {
            state.manageDocumentsResponse = null;
        });
        builder.addCase(saveApplicationDocuments.rejected, (state, action) => {
            state.saveApplicationDocuments = null;
        });
        builder.addCase(getApplicationFee.rejected, (state, action) => {
            state.applicationFee = null;
        });
        builder.addCase(finalizeApplication.rejected, (state, action) => {
            state.appFinalizeStatus = null;
        });
        builder.addCase(applicationAddToCart.rejected, (state, action) => {
            state.applicationToShoppingCartResponse = null;
        });
        builder.addCase(getApplicationStatusDetails.rejected, (state, action) => {
            state.applicationStatusDetails = null;
        });
        builder.addCase(saveResubmitApplicationDocument.rejected, (state, action) => {
            state.resubmitApplicationDocumentResponse = null;
        });
        builder.addCase(saveApplicationQuestionAnswer.rejected, (state, action) => {
            state.applicationQuestionAnswerResponse = null;
        });
        

        builder.addCase(getApplicationPasses.fulfilled, (state, action) => {
            state.applicationPassList = action.payload;
        });
        builder.addCase(getCustomerApplication.fulfilled, (state, action) => {
            state.customerApplication = action.payload;
        });
        builder.addCase(getDetailForApplicationPermitDate.fulfilled, (state, action) => {
            state.detailForApplicationPermitDate = action.payload;
        });
        builder.addCase(getPermitStartEndDate.fulfilled, (state, action) => {
            state.permitStartEndDate = action.payload;
        });
        builder.addCase(setApplicationPassDetail.fulfilled, (state, action) => {
            state.applicationPassDetail = action.payload;
        });
        builder.addCase(checkCustomerPassApplicationExistsOrNot.fulfilled, (state, action) => {
            state.customerPassApplicationExists = action.payload;
        });
        builder.addCase(saveCustomerApplicationInfo.fulfilled, (state, action) => {
            state.customerApplicationData = action.payload;
        });
        builder.addCase(getApplicationGlobalInputField.fulfilled, (state, action) => {
            state.applicationGlobalInputField = action.payload;
        });
        builder.addCase(getApplicationQuestionAnswer.fulfilled, (state, action) => {
            state.applicationQuestionAnswer = action.payload;
        });
        builder.addCase(getApplicationQuestion.fulfilled, (state, action) => {
            state.applicationQuestion = action.payload;
        });
        builder.addCase(getDocumentListByApplication.fulfilled, (state, action) => {
            state.documentListByApplication = action.payload;
        });
        builder.addCase(deleteCustomerApplicationDocuments.fulfilled, (state, action) => {
            state.deleteResponse = action.payload;
        });
        builder.addCase(manageDocumentsToEnigmaVault.fulfilled, (state, action) => {
            state.manageDocumentsResponse = action.payload;
        });
        builder.addCase(saveApplicationDocuments.fulfilled, (state, action) => {
            state.saveApplicationDocuments = action.payload;
        });
        builder.addCase(getApplicationFee.fulfilled, (state, action) => {
            state.applicationFee = action.payload;
        });
        builder.addCase(finalizeApplication.fulfilled, (state, action) => {
            state.appFinalizeStatus = action.payload;
        });
        builder.addCase(applicationAddToCart.fulfilled, (state, action) => {
            state.applicationToShoppingCartResponse = action.payload;
        });
        builder.addCase(getApplicationStatusDetails.fulfilled, (state, action) => {
            state.applicationStatusDetails = action.payload;
        });
        builder.addCase(saveResubmitApplicationDocument.fulfilled, (state, action) => {
            state.resubmitApplicationDocumentResponse = action.payload;
        });
        builder.addCase(saveApplicationQuestionAnswer.fulfilled, (state, action) => {
            state.applicationQuestionAnswerResponse = action.payload;
        });
    })
})

export const {  } = applicationSlice.actions;