import axios, { AxiosHeaderValue, AxiosResponse } from "axios";
import { store } from "../store/configureStore";
import { setAlertMessage } from "../../modules/common/settingsSlice";
import {
  customerLogOut,
  setIsSSOTokenExpired,
} from "../../modules/common/authenticationSlice";

const rdAxios = axios.create({
  withCredentials: true,
});

const authorizedRDAxios = axios.create({
  withCredentials: true,
});

const rdrAxios = axios.create({});

const authorizedRDAxiosFileUpload = axios.create({
  withCredentials: true,
});

authorizedRDAxiosFileUpload.interceptors.response.use(
  (response: any) => {
    return handleRDResponse(response);
  },
  (error) => {
    return handleErrorResponse(error);
  }
);

authorizedRDAxiosFileUpload.interceptors.request.use((config: any) => {
  return authorizedRDAxiosConfig(config, "multipart/form-data");
});

const authorizedRDAxiosConfig = (config: any, contentType: string) => {
  const accessToken = store.getState().authentication.accessToken;
  const installIdentity =
    store.getState().common.applicationSettings?.installIdentity;
  const storeId = store.getState().common.applicationSettings?.webStoreId;

  config.headers.InstallationsIdentity = installIdentity ? installIdentity : "";
  config.headers.StoreId = storeId ? storeId : "111";
  config.headers.accessToken = store.getState().authentication.ssoAccessToken;
  if (accessToken) {
    config.headers["Content-Type"] = contentType;
    config.headers.Authorization = "Bearer " + accessToken;
    return config;
  } else {
    store.dispatch(customerLogOut({ isOpenLogin: true }));
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) =>
        cancel("Cancel repeated request")
      ),
    };
  }
};

const responseBody = (response: AxiosResponse) => response.data;

const handleErrorResponse = (error: any) => {
  if (
    error.code === "ECONNABORTED" ||
    axios.isCancel(error)
  ) {
    console.log("Request was aborted:", error.message);
    return Promise.reject({
      isCanceled: true,
      originalError: error,
    });
  }
  if (error.code === "ERR_CANCELED") {
    return Promise.reject(error);
  } else {
    if (error.response && error.response.status === 401) {
      store.dispatch(setIsSSOTokenExpired(true));
      store.dispatch(customerLogOut({ isOpenLogin: true }));
    } else {
      if (
        error.config &&
        error.config.url &&
        error.config.url.includes("fd/citypark/namecontains")
      ) {
        return Promise.reject(error);
      } else {
        store.dispatch(
          setAlertMessage({
            className: "loginFailpopup",
            header: "Error",
            message: "",
          })
        );
        return Promise.reject(error);
      }
    }
  }
};

const handleRDResponse = (response: any) => {
  if (response.data) {
    if (response.data.Response === 1 || response.data.Response === 4) {
      if (response.data.Response === 4) {
        store.dispatch(
          setAlertMessage({
            className: "",
            header: "Success",
            message: response.data.Message,
          })
        );
      }
      response.data = response.data.Result;
      return response;
    } else {
      let message =
        response.data.Response === 3
          ? response.data.ExceptionString
          : response.data.Message;
      store.dispatch(
        setAlertMessage({
          className: "loginFailpopup",
          header:
            response.data.Result && response.data.Result.MessageHeader
              ? response.data.Result.MessageHeader
              : "Message",
          message: message,
        })
      );
      return Promise.reject(response);
    }
  }
  return response;
};

rdrAxios.interceptors.request.use((config: any) => {
  const installIdentity =
    store.getState().common.applicationSettings?.installIdentity;
  config.headers.tenantId = installIdentity ? installIdentity : "";
  return config;
});

rdrAxios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error) => {
    return handleErrorResponse(error);
  }
);

rdAxios.interceptors.response.use(
  (response: any) => {
    return handleRDResponse(response);
  },
  (error) => {
    return handleErrorResponse(error);
  }
);
authorizedRDAxios.interceptors.response.use(
  (response: any) => {
    return handleRDResponse(response);
  },
  (error) => {
    return handleErrorResponse(error);
  }
);

rdAxios.interceptors.request.use(async (config: any) => {
  const installIdentity =
    store.getState().common.applicationSettings?.installIdentity;
  const storeId = store.getState().common.applicationSettings?.webStoreId;
  const accessToken = store.getState().authentication.ssoAccessToken;
  if (
    config.url &&
    !config.url.toLowerCase().includes("webaccesscustomer/load/enterprise")
  ) {
    config.headers.InstallationsIdentity = installIdentity
      ? installIdentity
      : "";
  }
  config.headers.StoreId = storeId ? storeId : "111";
  config.headers.accessToken = store.getState().authentication.ssoAccessToken;
  if (accessToken) config.headers.Authorization = "Bearer " + accessToken;
  return config;
});

let isRdConfigLoaded = false;
let rdConfigPromise: Promise<void> | null = null;

rdAxios.interceptors.request.use(async (config: any) => {
  if (!isRdConfigLoaded) {
    if (!rdConfigPromise) {
      rdConfigPromise = fetch("/config.json")
        .then((response) => response.json())
        .then((configData) => {
          isRdConfigLoaded = true;
          rdAxios.defaults.baseURL = configData.rdApiUrl;
          authorizedRDAxios.defaults.baseURL = configData.rdApiUrl;
          authorizedRDAxiosFileUpload.defaults.baseURL = configData.rdApiUrl;
          rdrAxios.defaults.baseURL = configData.rdrApiUrl;
          config.baseURL = configData.rdApiUrl;
        })
        .catch((error) => {
          console.error("Failed to load config:", error);
          throw error;
        });
    }
    await rdConfigPromise;
  }

  return config;
});

authorizedRDAxios.interceptors.request.use((config: any) => {
  return authorizedRDAxiosConfig(config, "application/json");
});

export const rdRequests = {
  get: (url: string, params?: URLSearchParams) =>
    rdAxios.get(url, { params }).then(responseBody),
  post: (url: string, body: {}) => rdAxios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => rdAxios.put(url, body).then(responseBody),
  delete: (url: string) => rdAxios.delete(url).then(responseBody),
};

export const authorizedRDRequests = {
  get: (url: string, params?: URLSearchParams) =>
    authorizedRDAxios.get(url, { params }).then(responseBody),
  post: (url: string, body: {}) =>
    authorizedRDAxios.post(url, body).then(responseBody),
  put: (url: string, body: {}) =>
    authorizedRDAxios.put(url, body).then(responseBody),
  delete: (url: string) => authorizedRDAxios.delete(url).then(responseBody),
};

export const authorizedRDRequestsFileUpload = {
  post: (url: string, body: {}) =>
    authorizedRDAxiosFileUpload.post(url, body).then(responseBody),
};

export const authorizedRDRequestsv2 = {
  get: async <T>(url: string, params?: URLSearchParams): Promise<T> => {
    const response: AxiosResponse<T> = await authorizedRDAxios.get(url, {
      params,
    });
    return response.data;
  },

  post: async <T, B = {}>(url: string, body: B): Promise<T> => {
    const response: AxiosResponse<T> = await authorizedRDAxios.post(url, body);
    return response.data;
  },

  put: async <T, B = {}>(url: string, body: B): Promise<T> => {
    const response: AxiosResponse<T> = await authorizedRDAxios.put(url, body);
    return response.data;
  },

  delete: async <T>(url: string): Promise<T> => {
    const response: AxiosResponse<T> = await authorizedRDAxios.delete(url);
    return response.data;
  },
};

export const rdrRequests = {
  get: (url: string, params?: URLSearchParams) =>
    rdrAxios.get(url, { params }).then(responseBody),
  post: (url: string, body: {}) => rdrAxios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => rdrAxios.put(url, body).then(responseBody),
  delete: (url: string) => rdrAxios.delete(url).then(responseBody),
};
