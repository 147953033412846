import { WebDependentProfileInfo, WebDependentProfileResponse } from "../../models/customer/dependent";
import { Customer } from "../../models/customer";
import { rdRequests, authorizedRDRequests } from "../httpRequest";

const customerApiRequest = {
  getCustomerDependent: () => authorizedRDRequests.post(`webaccesscustomer/load/dependentdetails`,{}),
  getDependentProflie: (requestData: WebDependentProfileResponse) => authorizedRDRequests.post(`webaccesscustomer/load/dependentprofile`, requestData),
  getDependentDetail: (requestData: WebDependentProfileInfo) => authorizedRDRequests.post(`webaccesscustomer/load/dependentprofiledetail`, requestData),
  saveDependentProfileInfo: (requestData: WebDependentProfileInfo) => authorizedRDRequests.post(`webaccesscustomer/SaveDependentProfile`, requestData),
  getCustomerFormDetails: () => rdRequests.get(`WebAccessCustomer/Customer/CustomerFormDetails`),
  createCustomer: (request: Customer) => rdRequests.post(`WebAccessCustomer/Customer/CreateCustomer`, request),
  updateCustomer: (request: Customer) => authorizedRDRequests.post(`WebAccessCustomer/Customer/UpdateCustomer`, request),
  recoverPassword: (email: string) => rdRequests.get(`WebAccessCustomer/Customer/ForgotPassword?email=${email}`),
  getWaitListSignUp: () => authorizedRDRequests.post(`webaccessfacility/customerwaitlistdetail`, {}),
  cancelWaitList: (values: any) => authorizedRDRequests.post(`webaccessfacility/update/customerwaitlistdetail`, values),
  preCheckInDetails: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/PreCheckIn`, requestData),
  getCustomerProfileDetails: () => authorizedRDRequests.get(`WebAccessCustomer/Customer/CustomerProfileDetails`),
  savePreCheckInReservation: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/Save/PreCheckInReservation`, requestData),
  rePrintByConfirmationNo: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/RePrintByConfirmation`, requestData),
  getHouseHoldInfo: () => authorizedRDRequests.post(`WebAccessCustomer/HouseholdInfo`,{}),
  getCustomerReservationCartsBySearchParams: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/MyReservations`, (requestData)),
  getWaiverAgreementDetail: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/load/waiveragreement`, requestData),
  submitWaiverAgreementDetail: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/checkout/waiveragreement`, requestData),
  getCustomerPasses: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/CustomerPasses`, (requestData)),
  getCustomerSignUps: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/SignUpsBookings`, requestData),
  getCustomerHCSignUps: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/HCSignUpsBookings`, requestData),
  rePrintHCSignUpsByConfirmation: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/RePrintHCSignUpsByConfirmation`, requestData),
  rePrintSignUpsByConfirmation: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/RePrintSignUpsByConfirmation`, requestData),
  rePrintPassByConfirmation: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/RePrintPassByCustomerMembership`, requestData),
  getCustomerMemberships: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/Memberships`, (requestData)),
  getCustomerGiftPasses: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/CustomerGiftPasses`, (requestData)),
  rePrintCustomerMemberShips: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/RePrintYourMemberShips`, requestData),
  printGiftCardReceipt: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/PrintGiftCardReceipt`, requestData),
  updateGiftPassEmailDetails: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/UpdateGiftPassEmailDetails`, requestData),
  getGiftPassDetails: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/GetGiftPassDetails`, requestData),
  verifyValidAddress: (requestData: any) => rdRequests.post(`WebAccessCustomer/VerifyValidAddress`, requestData),
  validateCCPARemovalRequest: (isDeactivate: boolean) => authorizedRDRequests.post(`WebAccessCustomer/ValidateCCPARemovalRequest`, isDeactivate),
  sendDeactivateMailAndLogout: (requestData:any) => authorizedRDRequests.post(`WebAccessCustomer/SendDeactivateMailAndLogout`,requestData),
  updateOptOutStatus: (isChecked: boolean) => authorizedRDRequests.post(`WebAccessCustomer/UpdateOptOutStatus`, isChecked),
  verifyCustomerIdVerificationDetails: (requestData:any) => rdRequests.post(`WebAccessCustomer/VerifyCustomerIdVerificationDetails`,requestData),
  checkAddress: (request: any) => authorizedRDRequests.post(`WebAccessCustomer/CheckAddress`, request),
  updateCustomerAddress: (request: number) => authorizedRDRequests.post(`WebAccessCustomer/Update/CustomerAddress`, request),
  getCustomerIdExpirationDate:(referenceNumber: number) => rdRequests.get(`WebAccessCustomer/GetCustomerIdExpirationDate?referenceNumber=${referenceNumber}`),
  getGroupCustomerFormDetails: () => rdRequests.get(`WebAccessCustomer/Customer/GroupCustomerFormDetails`),
  getGroupCustomerProfileDetails: () => authorizedRDRequests.get(`WebAccessCustomer/Customer/GroupCustomerProfileDetails`),
  createGroupCustomer: (request: Customer) => rdRequests.post(`WebAccessCustomer/Customer/CreateGroupCustomer`, request),
  updateGroupCustomer: (request: Customer) => authorizedRDRequests.put(`WebAccessCustomer/Customer/UpdateGroupCustomer`, request),
  getCustomerMerchandise: (requestData: any) => authorizedRDRequests.post(`WebAccessCustomer/Load/CustomerMerchandise`, (requestData)),
  verifyAccountInformation: (request: Customer) => authorizedRDRequests.put(`WebAccessCustomer/Customer/VerifyAccountInformation`, request),
  getCustomer: (customerId: number) => authorizedRDRequests.get(`WebAccessCustomer/GetCustomer?customerId=${customerId}`),
  rePrintApplicationPermitByConfirmation: (requestData: any) => authorizedRDRequests.post(`WebAccessApplication/RePrintApplicationPermitByConfirmation`, requestData),
}

export default customerApiRequest;