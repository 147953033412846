import { format } from "date-fns/format";
import { store } from "../store/configureStore";
import {
  setAlertMessage,
  setIsSSOAlertAfterVerified,
} from "../../modules/common/settingsSlice";
import axios from "axios";
import { onOpenModal } from "./ada";
import { getCMSPageContent } from "../../modules/common/settingsSlice";
import navigationService from "../../app/history/history";
import { setSearchStep } from "../../modules/facilities/components/search/searchSlice";
import {
  setIsLoginPopUpOpen,
  setIsSSOLoginPopUpOpen,
  setIsSSOLoginPopUpOpenFromUnitDetail,
} from "../../modules/common/authenticationSlice";
import { parse } from "date-fns";

export const EnableCSS = (key: string) => {
  let localStyleSheet = document.styleSheets as unknown as any[];
  for (let i = 0; i < localStyleSheet.length; i++) {
    if (!localStyleSheet[i].href) {
      for (let z = 0; z < localStyleSheet[i].cssRules.length; z++) {
        if (localStyleSheet[i].cssRules[z].cssText.includes(key)) {
          localStyleSheet[i].disabled = false;
        }
      }
    }
  }
};

export const DisableCSS = (key: string) => {
  let localStyleSheet = document.styleSheets as unknown as any[];
  for (let i = 0; i < localStyleSheet.length; i++) {
    if (!localStyleSheet[i].href) {
      for (let z = 0; z < localStyleSheet[i].cssRules.length; z++) {
        if (localStyleSheet[i].cssRules[z].cssText.includes(key)) {
          localStyleSheet[i].disabled = true;
        }
      }
    }
  }
};

export const addScript = (url: string, id: string) => {
  let scriptEle = document.getElementById(id);
  if (!scriptEle) {
    let script = document.createElement("script");
    script.src = url;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
  }
};
export const addDynamicScript = (page: string) => {
  store
    .dispatch(getCMSPageContent({ pageName: page }))
    .then((response: any) => {
      if (response.payload && response.payload.CMSContain) {
        const scriptElement = document.createElement("script");
        const tempElement = document.createElement("div");
        tempElement.innerHTML = response.payload.CMSContain;
        const scriptTag: any = tempElement.querySelector("script");
        scriptElement.textContent = scriptTag.textContent;
        scriptElement.id = "dynamicscripttag";
        document.body.appendChild(scriptElement);
      }
    });
};
export const addCSS = (url: string) => {
  var head = document.getElementsByTagName("head")[0];
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = url;
  link.media = "all";
  head.appendChild(link);
};

export const getTemplateData = (
  file: string,
  enterpriseName: string,
  callback: any
) => {
  axios
    .get(
      store.getState().common.applicationSettings.imageBaseUrl +
        "themes/" +
        enterpriseName +
        "/" +
        file
    )
    .then((response) => {
      if (response.data && response.data.includes("baseIdForEnterprise")) {
        callback("");
      } else {
        callback(response.data);
      }
    })
    .catch((error) => {
      callback("");
    });
};

export const formatDateToMMDDYYYY = (date: string, dateFormat: string) => {
  let newDate = "";
  if (dateFormat === "MM/dd/yyyy") {
    newDate = date;
  } else if (dateFormat === "dd/MM/yyyy") {
    let dateArray = date.split("/");
    newDate = date[1] + "/" + date[0] + "/" + date[2];
  }
  return newDate;
};

export const formatDateObjectToMMDDYYYY = (date: any) => {
  let dd = date.getDate();
  let MM = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  dd = dd <= 9 ? "0" + dd : dd;
  MM = MM <= 9 ? "0" + MM : MM;

  return MM + "/" + dd + "/" + yyyy;
};

export const formatDate = (date: any, dateFormat: string) => {
  if (typeof date === "string" && date.length === 10 && date.includes("-")) {
    date = parse(date, "yyyy-MM-dd", new Date());
  } else {
    date = new Date(date);
  }
  return format(date, dateFormat);
};

export const formatDateInTimeZone = (date: any, dateFormat: string) => {
  date = new Date(date + "T00:00:00");
  return format(date, dateFormat);
};

export const formatDateWithTimeZone = (date: any, dateFormat: string) => {
  date = new Date(date);
  return format(date, dateFormat) + " 00:00:00";
};

export const getDateWithTimeZone = (date: any) => {
  return new Date(date + " 00:00:00");
};

export const formatDateToDisplay = (date: any) => {
  date = new Date(date);
  return format(date, store.getState().common.applicationSettings.dateFormat);
};

export const formatDateWithDayAndMonthName = (date: any) => {
  date = new Date(date);
  return format(date, "EEEE, MMMM dd, yyyy");
};

export const getDayDifference = (date1: any, date2: any) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Math.ceil(Difference_In_Days);
};

export const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const camalize = (text: any) => {
  return text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
};

export const removeQueryParam = () => {
  if (window.location.href.includes("?")) {
    window.history.replaceState({}, "", window.location.href.split("?")[0]);
  }
};

export const getDefaultSearchData = (
  queryDate: string,
  queryNight: number,
  callback: any
) => {
  let bookingDetail: any = localStorage.getItem("booknow")
    ? JSON.parse(localStorage.getItem("booknow") || "{}")
    : null;
  let minDate = store.getState().common.futureBookingsDate
    .FutureBookingStartDate
    ? store.getState().common.futureBookingsDate.FutureBookingStartDate
    : new Date();
  let startDate = new Date(minDate);
  let endDate = new Date(new Date(minDate).getTime() + 1000 * 60 * 60 * 24);
  let nights: any = queryNight > 0 ? queryNight : 1;
  let parkId =
    store.getState().common.webSiteSettings?.facility_default_place_id;
  if (bookingDetail) {
    startDate = bookingDetail.SearchStartDate
      ? new Date(bookingDetail.SearchStartDate)
      : startDate;
    endDate = bookingDetail.SearchEndDate
      ? new Date(bookingDetail.SearchEndDate)
      : endDate;
    nights = bookingDetail.Nights ? bookingDetail.Nights : nights;
    parkId = bookingDetail.PlaceId ? bookingDetail.PlaceId : parkId;
    localStorage.removeItem("booknow");
  } else if (queryDate) {
    startDate = new Date(queryDate);
    endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * nights);
  } else {
    if (
      sessionStorage.getItem("selectedStartdate") &&
      sessionStorage.getItem("selectedEnddate")
    ) {
      startDate = new Date(sessionStorage.getItem("selectedStartdate") || "");
      endDate = new Date(sessionStorage.getItem("selectedEnddate") || "");
    }
    nights = sessionStorage.getItem("selectedNights")
      ? sessionStorage.getItem("selectedNights")
      : nights;
  }
  callback(startDate, endDate, nights, parkId);
};

export const getFormattedTime = (date: any) => {
  let currentDate = new Date(date);
  let hour = currentDate.getHours();
  let minute = currentDate.getMinutes();
  let suffix = hour >= 12 ? "pm" : "am";
  return (
    ("0" + (hour > 12 ? hour - 12 : hour)).slice(-2) +
    ":" +
    ("0" + minute).slice(-2) +
    " " +
    suffix
  );
};

export const convertAMPMToTimeStamp = (time: any) => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === "pm" && hours < 12) hours = hours + 12;
  if (AMPM === "am" && hours === 12) hours = hours - 12;
  let sHours: any = hours.toString();
  let sMinutes: any = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return new Date(2021, 1, 1, sHours, sMinutes, 0, 0).getTime();
};

export const convertAMPMToTime = (time: any) => {
  if (time == null || time == undefined) return;
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM == "pm" && hours < 12) hours = hours + 12;
  if (AMPM == "am" && hours == 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

export const setDocumentTitle = (title: any) => {
  document.title = title;
};

export const skipContent = () => {
  let element: any = document.getElementsByClassName("skip-to-content-focus");
  element[0].focus();
};

export const setFocusToElement = (ref: any, isClass: any) => {
  if (isClass) {
    let element: any = document.getElementsByClassName(ref);
    if (element && element.length > 0) {
      element[0].focus();
    }
  } else {
    let element = document.getElementById(ref);
    if (element) {
      element.focus();
    }
  }
};

export const getAPIUrl = () => {
  return import.meta.env.VITE_RDR_API_URL;
};

export const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const onFacilityLoad = () => {
  let availableLead = document.getElementById("available-facility-lead");
  if (availableLead) {
    availableLead.focus();
  } else {
    setTimeout(() => {
      onFacilityLoad();
    }, 500);
  }
};

export const changeIdOfList = () => {
  setTimeout(() => {
    let elements: any = document.getElementsByClassName(
      "select-options-container"
    );
    if (elements && elements.length > 0) {
      let index = 0;
      for (let ele of elements) {
        ele.setAttribute("id", "listbox--" + index);
        index++;
      }
    }
  }, 500);
};

const shiftTabOnMap = (event: any) => {
  if (event.shiftKey && event.keyCode === 9) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    var element = document.getElementById("back-to-park");
    if (element) {
      element.focus();
    }
    return false;
  } else {
    return true;
  }
};

const availableSiteTabKeyHandler = (e: any) => {
  var TABKEY = 9;
  if (e.keyCode === TABKEY) {
    let favElement = document.getElementsByClassName("unit-grid-fav");
    if (favElement && favElement.length > 0) {
      return true;
    } else {
      if (e.preventDefault) {
        e.preventDefault();
      }
      var element = document.getElementById("grid-search-map");
      if (element) {
        element.focus();
        if (element.addEventListener) {
          element.addEventListener("keydown", shiftTabOnMap, false);
        }
        setGridTabIndex(0, function (index: any) {});
      }
      return false;
    }
  }
};

export const setGridTabIndex = (tabIndex: any, callback?: any) => {
  let availableSite: any = document.getElementsByClassName("available-unit");
  let index = 0;
  if (availableSite && availableSite.length > 0) {
    let indexArray = [];
    for (let element of availableSite) {
      let firstAvailableClassList = element.classList;
      indexArray.push(
        parseInt(firstAvailableClassList[firstAvailableClassList.length - 1])
      );
    }
    if (indexArray.length > 0) {
      indexArray.sort();
      index = indexArray[0];
      if (tabIndex === 0) {
        for (let element of availableSite) {
          element.setAttribute("tabindex", 0);
        }
        if (callback) {
          callback(null);
        }
      } else {
        let elementArray = [];
        for (let element of availableSite) {
          element.setAttribute("tabindex", 0);
          elementArray.push(element);
        }
        if (callback) {
          callback(elementArray[0]);
        }
      }
    } else {
      if (callback) {
        callback(null);
      }
    }
  } else {
    if (callback) {
      callback(null);
    }
  }
};

const noSiteAvailableTabKeyHandler = (e: any) => {
  var TABKEY = 9;
  if (e.keyCode === TABKEY) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    var element = document.getElementById("back-to-park");
    if (element) {
      element.focus();
    }
    return false;
  }
};

export const onGridLoad = (isOpenCamping: boolean) => {
  let availableSite = document.getElementsByClassName(
    "available-unit"
  ) as HTMLCollectionOf<HTMLElement>;
  let notAvailableSite = document.getElementsByClassName("not-available-unit");
  let noSites = document.getElementById("no-sites");
  if (noSites) {
    noSites.style.display = "none";
  }
  if (availableSite.length > 0 || notAvailableSite.length > 0) {
    if (isOpenCamping) {
      if (availableSite.length > 0) {
        availableSite[0].focus();
      }
    } else {
      if (availableSite.length > 0) {
        setGridTabIndex(null, function (element: HTMLElement) {
          let favElement = document.getElementsByClassName(
            "unit-grid-fav"
          ) as HTMLCollectionOf<HTMLElement>;
          if (favElement && favElement.length > 0) {
            favElement[0].focus();
          } else {
            element.focus();
          }
        });
      } else if (availableSite.length > 0 && notAvailableSite.length === 0) {
        setGridTabIndex(null, function (element: HTMLElement) {
          let favElement = document.getElementsByClassName(
            "unit-grid-fav"
          ) as HTMLCollectionOf<HTMLElement>;
          if (favElement && favElement.length > 0) {
            favElement[0].focus();
          } else {
            element.focus();
          }
        });
      } else {
        let noSites = document.getElementById("no-sites") as HTMLElement;
        if (noSites) {
          noSites.style.display = "block";
          setTimeout(() => {
            noSites.focus();
            if (noSites.addEventListener) {
              noSites.addEventListener(
                "keydown",
                noSiteAvailableTabKeyHandler,
                false
              );
            }
          }, 1000);
        }
      }
    }
  } else {
    setTimeout(() => {
      onGridLoad(isOpenCamping);
    }, 500);
  }
};

export const openLoginModal = (isFromUnitDetail?: boolean) => {
  store.dispatch(setIsSSOAlertAfterVerified(false));
  onOpenModal(
    "ssoLoginAlert",
    "ssoLoginAlertHeader",
    "ssoLoginAlertInfo",
    "ssoLoginAlertHeader"
  );
  store.dispatch(setIsSSOLoginPopUpOpen(true));
  if (isFromUnitDetail) {
    store.dispatch(setIsSSOLoginPopUpOpenFromUnitDetail(true));
  }
};

export const checkLogin = (isFromUnitDetail?: boolean) => {
  const isLogin = store.getState().authentication.isLoggedIn;
  if (isLogin) {
    return true;
  } else {
    openLoginModal(isFromUnitDetail);
    return false;
  }
};

export const timediff = (startDate: any, currentDateTime: any) => {
  let start = new Date(startDate);
  let end = new Date(currentDateTime);
  let difference_In_Time = Math.abs(start.getTime() - end.getTime()) / 1000;
  if (difference_In_Time < 60) {
    return Math.round(difference_In_Time).toString() + " seconds ago";
  } else if (difference_In_Time / 60 < 60) {
    return Math.round(difference_In_Time / 60).toString() + " minute(s) ago";
  } else if (difference_In_Time / 3600 < 24) {
    return Math.round(difference_In_Time / 3600).toString() + " hour(s) ago";
  } else {
    return Math.round(difference_In_Time / 86400).toString() + " day(s) ago";
  }
};

export const encryptCustomerId = (customerId: any) => {
  return btoa(customerId.toString());
};

export const decryptCustomerId = (customerId: any) => {
  return atob(customerId.toString());
};

export const getFormattedPrice = (price: any) => {
  return "$" + (Math.round(price * 100) / 100).toFixed(2).toString();
};

export const encryptInstallationIdentity = (installationIdentity: any) => {
  return btoa(installationIdentity.toString());
};

export const decryptInstallationIdentity = (installationIdentity: any) => {
  return atob(installationIdentity.toString());
};

export const onlyNumber = (evt: any) => {
  if (!/[0-9]/.test(evt.key)) {
    evt.preventDefault();
  }
};

export const removeSpecialChar = (event: any) => {
  if (document.getElementById(event.currentTarget.id)) {
    (window as any).document
      .getElementById(event.currentTarget.id)
      .removeAttribute("maxlength");
    var _this = (window as any).document.getElementById(event.currentTarget.id);
    setTimeout(function () {
      var numsStr = _this.value.replace(/[^0-9]/g, "");
      numsStr = numsStr.substring(0, 10);
      _this.setAttribute("maxlength", "10");
      _this.value = numsStr;
    }, 100);
  }
};

export const phoneNumberValidation = (
  evt: any,
  isMessageShow: boolean,
  message: string
) => {
  if (evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
    if (isMessageShow) {
      store.dispatch(
        setAlertMessage({
          className: "loginFailpopup",
          header: "Message",
          message: message,
        })
      );
    }
    evt.preventDefault();
  }
};

export const onlyNumberWithFloat = (evt: any) => {
  if (/[^0-9.]/.test(evt.key)) {
    evt.preventDefault();
  }
  if (evt.charCode === 46 && evt.target.value.split(".").length === 2) {
    evt.preventDefault();
  }
};

export const registerScripts = (scriptUrl: string) => {
  const script = document.createElement("script");
  script.src = scriptUrl;
  document.body.appendChild(script);
};

export const replaceBRTag = (stringVal: string) => {
  return stringVal.replace(/\\r\\n|\\n|\\r|\r\n|\n|\r/g, "<br/>");
};

export const addOneYear = (date: any) => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 1);
  return dateCopy;
};

export const validateTime = (time: any) => {
  return String(time)
    .toLowerCase()
    .match(/^\b((1[0-2]|0?[1-9]) ([AaPp][Mm])$)/);
};

export const navigateToHomePage = () => {
  store.dispatch(setSearchStep("home"));
  navigationService.navigation("/");
};

export const replaceBasePath = (value : string) => {
  let basePath = store.getState().common.applicationSettings.imageBaseUrl;
  return value ? value.replace(/#BasePath#/g, basePath) : value;
}